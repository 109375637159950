@import "rsuite/dist/rsuite.css";

.rs-drawer-body {
    padding: 10px 14px;
}

@media screen and (max-width:800px) {
    .rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs {
        width: 100%;
    }
}
