@import "rsuite/dist/rsuite.css";

.phone-input-container {
    display: inline-grid;
    width: 100%;
    grid-row-gap: 10px;
    margin-top: 80px;
}

.phone-number-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    padding: 20px;
}

.btn-login, .btn-signup, .btn-verify-otp {
    width: 100%;
    height: 40px;
    line-height: 35px;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #409f7a;
    margin-top: 20px;
}

.btn-login:hover,
.btn-signup:hover,
.btn-verify-otp {
    opacity: 0.8;
}

.react-tel-input .form-control {
    width: 100%;
}

.login-email, .register-email {
    margin-left: auto;
    margin-right: auto;
    width: 94%;
    padding: 20px;
    min-height: 700px;
}

.login-email input, .register-email input {
    width: 100%;
    margin: 10px 0 0 0;
    height: 30px;
}

.login-email button, .register-email button {
    width: 100%;
    height: 40px;
    line-height: 35px;
    background-color: #409f7a;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.login-email button[disabled], .register-email button[disabled] {
    background: #C8D5B9;
}

.error-message {
    font-size: 14px;
    font-weight: 600;
}

.error-message a {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    color: #409f7a;
    margin-left: 6px;
    cursor: pointer;
}
.instruction {
    font-size: 10px;
    margin-top: -10px;
}

.error {
    color: #f44336;
}

.form-error {
    display: block;
    padding: 10px 0px;
}

.spinner {
    width: 20px;
}

.btn-forgot-password {
    margin: 14px auto;
    display: table;
    cursor: pointer;
}
