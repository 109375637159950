.new-address-form {
  margin-top: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.select-apartment, .address-input {
  width: 100% !important;
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  display: block;
  max-width: 90%;
}

.btn-save {
    width: 100%;
    height: 40px;
    line-height: 35px;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #409f7a;
    margin-top: 20px;
}

.btn-save:hover {
  opacity: 0.8;
}


@media screen and (max-width: 660px) {
  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    display: block;
    max-width: 100%;
  }
}
