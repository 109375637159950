.account-container {
    width: 100%;
    padding: 20px;
}

.logout-btn {
    margin: 20px auto;
    width: 90%;
    height: 40px;
    line-height: 35px;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    background-color: #409f7a;
}

.logout-btn:hover {
  opacity: 0.8;
}

