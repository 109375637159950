.banner {
    display: none;
}
@media screen and (max-width: 660px) {
    .banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    width: 100%;
    padding: 14px 16px;
    }

    .banner_content {
        width: auto;
        height: auto;
        border-radius: 6px;
        background-color: #AFC95D;
        overflow: hidden;
    }

    .banner_text {
        color: #FFFFFF;
        position: absolute;
        padding: 40px;
    }

    .banner_round1 {
        width: 100px;
        height: 100px;
        background-color: #73A859;
        border-radius: 50px;
        margin: -42px 0px 0 -10px;
    }

    .banner_round2 {
        width: 150px;
        height: 150px;
        background-color: #DDD37C;
        border-radius: 75px;
        float: right;
        margin: -50px;
    }

    .rs-carousel {
        height: auto;
    }
}