.confirmation-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.2;
  padding-top: 20px;
}
  
.spinner {
  font-size: 4rem;
  animation: spin 1s linear infinite;
}

.spinner-text {
  display: inline-block;
  text-align: center;
  margin-top: 100px;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  
.spinner-container {
  margin-top: 150px;
}

.confirmation-col {
  display: grid;
  grid-gap: 10px;
}

.delivery-address {
  display: grid;
}
