.filter_container, .filter_image_container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4px 16px 5px;
    position: relative;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.filter_container {
    position: relative;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.filter_image_container {
    display: flex;
    flex-wrap: wrap;
}

.filter_image_container .filter_item {
    width: 210px;
    height: auto;
    margin: 5px 10px;
    text-align: center;
}

.filter_item .filter_text_selected, .filter_item .filter_text {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #409F7A;
    line-height: 40px;
}

.filter_item .filter_text {
    color: #409F7A;
}

.filter_item .filter_text_selected {
    color: #333;
    text-decoration: underline;
    font-weight: 600;
}

.filter_image_container .filter_item img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    padding: 10px;
    background-color: #FFFFFF;
}

.filter_container::-webkit-scrollbar {
    display: none;
}

.filter_btnBox {
    display: flex;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    gap: 10px;
    padding: 4px 5px;
}

.filter_btnBox::-webkit-scrollbar {
    height: 3px;
}

.filter_btnBox::-webkit-scrollbar-thumb {
    background-color: #409f7a;
    border-radius: 3px;
}

.filter_icon {
    width: 80px;
    color: #409f7a;
    padding: 0;
}
.filter_btn {
    white-space: nowrap;
}

.filter_btn,
.filter_btn_selected {
    height: 35px;
    line-height: 35px;
    display: block;
    background-color: #96C08C;
    border: none;
    outline: none;
    color: #409f7a;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all .3s ease;
    padding: 0;
    margin-right: 26px;
    border-radius: 6px;
    padding: 0 10px;
    color: #FFF;
}

.filter_btn_selected {
    background-color: #409F7A;
}

.filter_btn:hover {
    border-bottom: 2px solid #FAF3DD;
}

.filter_title {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 10px 4px 20px;
    width: 100%;
    max-width: 1200px;
    margin:auto;
}

.filter_text_selected {
    color: #409F7A;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 800px) {
    .filter_image_container .filter_item {
        width: 200px;
    }
    .filter_title {
        padding: 4px 10px 4px 16px;
    }
}


@media screen and (max-width: 600px) {
    .filter_btn,
    .filter_btn_selected {
        margin-right: 10px;
    }
    .filter_image_container .filter_item {
        width: 168px;
    }
}

@media screen and (max-width: 550px) {
    .filter_image_container .filter_item {
        width: 200px;
    }
}

@media screen and (max-width: 500px) {
    .filter_image_container .filter_item {
        width: 200px;
    }
}

@media screen and (max-width: 450px) {
    .filter_image_container .filter_item {
        width: 170px;
    }
}

@media screen and (max-width: 400px) {
    .filter_image_container .filter_item {
        width: 150px;
    }
}

@media screen and (max-width: 360px) {
    .filter_image_container .filter_item {
        width: 138px;
    }
}

@media screen and (max-width: 300px) {
    .filter_image_container .filter_item {
        width: 114px;
    }
}

@media screen and (max-width: 280px) {
    .filter_image_container .filter_item {
        width: 100px;
    }
}
