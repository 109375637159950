.product_container {
  width: 100%;
  max-width: 1200px;
  min-height: 450px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  row-gap: 20px;
  padding: 16px;
}

.box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 10px;
  position: relative;
  max-height: 390px;
  min-width: 162px;
}

.box-content {
  width: 100%;
}

.box a{
  text-align: center;
  text-decoration: none;
}

.product-img-container {
  min-height: 170px;
}

.product_img {
  width: 100%;
  max-height: 170px;
}

.product-content {
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
}

.product-content-texts {
  min-height: 68px;
}

.title {
  width: 100%;
  margin-top: 10px;
}

.multi_variant_title button {
  padding: 0;
  background-color: #fff;
  color: #409f7a;
  font-weight: bold;
  margin-right: 2px;
  font-size: 10px;
}

.multi_variant_title button:hover {
  background-color: #fff;
}

.price {
  width: 100%;
  margin-top: 5px;
}

.price span {
  font-size: 15px;
}

.products_button {
  width: 100%;
  height: 35px;
  line-height: 35px;
  display: block;
  margin-top: 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buy_button {
  background-color: #409f7a;
}

.add_button {
  background-color: #4A7C59;
}

.buy_button:hover {
  background-color: #C8D5B9;
}

.add_button:hover {
  background-color: #ff8000;
}

.buy_icon {
  font-size: 18px;
}

.Interest {
  width: 25px;
  height: 25px;
  background-color: #eee;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 10;
}

.heart_Fill,
.heart {
  color: #f00;
}

.not_products {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.products_empty_title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.products_empty_img {
  width: 230px;
  opacity: 0.6;
}

.products_empty_guide {
  font-size: 16px;
  color: #ed6a5a;
  text-align: center;
}

.search_Container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.search_box {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 20px;
}

/* Media  */

@media screen and (min-width: 500px) {
  .favorite_container,
  .product_container {
    grid-template-columns: repeat(3, 1fr);
  }

  .basket_img img {
    width: 110px;
  }

  .basket_buttons {
    gap: 10px;
    padding-top: 10px;
  }

  .counter_number {
    width: 40px;
    height: 30px;
  }

  .basket_title {
    font-size: 18px;
  }

  .basket_content,
  .basket_counter {
    padding: 10px 15px;
  }
}

@media screen and (min-width: 800px) {
  .favorite_container,
  .product_container {
    grid-template-columns: repeat(4, 1fr);
  }

  .basket_container {
    flex-direction: row;
  }

  .basket_itemBox {
    width: 60%;
  }

  .basket_priceBox {
    width: 40%;
  }
}

@media screen and (min-width: 1000px) {
  .favorite_container,
  .product_container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 660px) {
  .search_Container {
    margin-top: 4px;
  }
}
