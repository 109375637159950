.basket_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;
}

.basket_plus,
.basket_minus {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.basket_plus {
  background-color: #409f7a;
}

.counter_number {
  width: 35px;
  height: 30px;
  display: flex;
  border: 2px solid #409f7a;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.basket_minus {
  background-color: #ed6a5a;
  color: #fff;
}

.basket_plus:hover,
.basket_minus:hover {
  opacity: 0.8;
}
