.header {
  width: 100%;
  background-color: #409f7a;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  min-height: 70px;
}

.nav {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px;
}

.icon_Sopping_box {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;
  height: 59px;
}

.shoppe_icon_box,
.user_icon_box {
  position: relative;
}

.shoppe_icon_box:hover .shop_icon, .user_icon_box:hover .use_icon {
  transform: translateY(-3px);
}

.shop_icon, .use_icon {
  transform: translateY(-3px);
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: #fff;
  transition: all 0.3s;
  font-size: 24px;
  margin-bottom: -4px;
}

.nav_text {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 10px;
}

.selected_location, .deliveryTime {
  text-align: left;
  width: 100%;
  color: #000;
  padding: 6px 14px;
}

.selected_location {
  background-color: #daf0e7;
}

.deliveryTime {
  background-color: #FFF;
}

.selected_location div, .deliveryTime div {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.selected_location span, .deliveryTime span {
  margin-left: 4px;
}

.badge_shope,
.badge_mark {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -3px;
  bottom: 0;
  border-radius: 20%;
  background-color: #ed6a5a;
  font-size: 15px;
  color: #fff;
}

.badge_mark {
  right: -5px;
  bottom: -5px;
}

.logo {
  color: #fff;
  font-size: 20px;
}

.logo img {
  width: 50px;
}

.search_header {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  10%,
  20% {
    transform: scale3d(0.8, 0.66, 0.66) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.tada {
  animation-name: tada;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

@media screen and (max-width: 660px) {
  .header {
    display: none;
  }
  .selected_location {
    background-color: #F6F8FA;
    margin: 4px 0;
  }
  .selected_location span {
    margin-left: 20px;
    font-size: 16px;
  }
  .deliveryTime {
    display: none;
  }
}

@media (pointer:none), (pointer:coarse) {
  .header {
    display: none;
  }
  .selected_location {
    background-color: #F6F8FA;
    margin: 4px 0;
  }
  .selected_location span {
    margin-left: 20px;
    font-size: 16px;
  }
  .deliveryTime {
    display: none;
  }
}