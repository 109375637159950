.searchBar_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FA;
}

.searchBar_box input {
  height: 35px;
  width: 80%;
  padding-left: 20px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 6px 0 0 6px;
}

.searchBar_box input:focus{
  border: 2px solid #4A7C59;
}

.searchBar_box input::placeholder {
  color: rgba(0, 0, 0, 0.4);

}

.searchBar_box span {
  width: 20%;
  height: 38px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #409f7a;
  color: #fff;
  border-radius: 0 6px 6px 0;
  font-size: 20px;
}
