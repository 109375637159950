.price-list {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 4px;
    margin: 20px auto;
    width: 90%;
}

.price-list .product-img {
    width: 30%;
    height: 30%;
    max-height: 100px;
    max-width: fit-content;
}

.price-list-content {
    margin-left: 20px;
}