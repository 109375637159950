.checkout_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 6px;
}

.checkout_box {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  position: relative;
  margin: 20px 0;
}

.checkout_box_title {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout_box_title span {
  font-size: 16px;
}

.checkout_box_title span.checkout_subtitle {
  font-weight: bold;
}

.checkout_container_left {
  min-width: 60%;
}

.checkout_confirm {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
}

.checkout-total {
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  text-align: left;
  display: block;
  width: 100%;
}

.checkout-total-amount {
  float: right;
  padding-right: 4px;
}

.checkout-total-line {
  width:100%;
  height: 1px;
  background-color: #EEEEEE;
}

.basket-container {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 1000px) {
  .checkout_show_grid {
    min-width: 800px;
  }
}
