.user-dropdown {
  display: inline-block;
  position: relative;
  margin-right: -15%;
}

.dropdown-menu-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 150px;
  height: 150px;
}

.dropdown-menu {
  position: relative;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style: none;
}
.dropdown-menu ul {
  padding-left: 0;
}
.dropdown-menu li {
  padding: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  list-style: none !important;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width:800px) {
  .user-dropdown {
    margin-right: 0px;
  }
}