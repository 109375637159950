.footer {
    width: 100%;
    background-color: #96C08C;
    position: relative;
    padding: 20px 0;
}
.footer-content {
    max-width: 1200px;
    display: flex;
    justify-content: right;
    background-color: #96C08C;
}

.footer-content button {
    padding: 10px 0;
    background: #96C08C;
    color: white;
    width: 100%;
    border: 0;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}

.logo-content {
    width: 500px;
    color: #ffffff;
}

.collapse {
    overflow: hidden;
    transition: 0.4s max-height;
}
  
.collapse > div {
    overflow: auto;
}
  
.collapse-content {
    color: #FFFFFF;
    display: grid;
    width: 170px;
    align-content: flex-start;
}

.collapse-content a, .collapse-content a:visited {
    color: #FFFFFF;
}

button span {
    color: #FFFFFF;
    float: right;
    margin-right: 20px;
}

@media screen and (max-width:800px) {
    .footer {
        margin: 42px 0;
    }
    .footer-content {
        display: grid;
        justify-content: flex-start;
        padding-left: 10px;
        gap: 14px;
    }
    .collapse-content {
        margin-bottom: 16px;
    }
    .logo-content {
        width: 100%;
    }
}
