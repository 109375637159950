@import "rsuite/dist/rsuite.css";

.investorContainer {
    margin-top: 100px;
    padding: 20px;
}
.investmentLines {
    display: block;
    padding: 4px 0;
}
.investmentLines b {
    font-weight: 500;
}
.activities {
    border-bottom: 2px solid #fff;
    padding: 10px 0;
}
