@import "rsuite/dist/rsuite.css";

.event-container{
    margin-left: auto;
    margin-right: auto;
    width: 94%;
    padding: 20px;
    min-height: 700px;
}

.event-container {
    margin-left: auto;
    margin-right: auto;
    width: 94%;
    padding: 20px;
    min-height: 700px;
}

.event-container input {
    width: 100%;
    margin: 10px 0 0 0;
    height: 30px;
}

.event-container button {
    width: 100%;
    height: 40px;
    line-height: 35px;
    background-color: #409f7a;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.instruction {
    font-size: 10px;
    margin-top: -10px;
}

.spinner {
    width: 20px;
}
