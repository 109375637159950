.footer_menu {
    display: none;
    height: 60px;
    width: 100%;
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    padding: 10px;
    box-shadow: 0px -2px rgba(0, 0, 0, 0.1);
}

.footer_menu_content {
    display: flex;
    justify-content: center;
    align-content: space-between;
}

.footer_menu_item svg {
    width: 28px;
    height: 28px;
}

.footer_menu_item {
    padding: 0 18px;
    color: #96C08C;
    display: grid;
    font-size: 10px;
    text-align: center;
}

.footer_menu_item_round {
    padding: 0 5px;
}


.footer_menu_item_round {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 -2px rgba(0,0,0,.1);
    height: 80px;
    margin-top: -40px;
    width: 80px;
}

.footer_menu_item svg {
    margin-left: auto;
    margin-right: auto;
}

.footer_menu_item_round img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    width: 70px;
}

.footer_menu_item.active {
    color: #409F7A;
}

span.badge_shope {
    position: relative;
    margin-top: -20px;
}

@media screen and (max-width:800px) {
    .footer_menu {
        display: block;
    }
}

@media screen and (max-width:500px) {
    .footer_menu {
        display: block;
    }
}


@media (pointer:none), (pointer:coarse) {
    .footer_menu {
        display: block;
    }
}
