.order-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.2;
  padding-top: 20px;
}
  
.spinner {
  font-size: 4rem;
  animation: spin 1s linear infinite;
}

.spinner-text {
  display: inline-block;
  text-align: center;
  margin-top: 100px;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  
.spinner-container {
  margin-top: 150px;
}

.order-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 10px 10px;
  transition: all 0.3s ease;
  position: relative;
  max-width: 600px;
  margin: 14px;
}

.order-item a{
  text-align: center;
  text-decoration: none;
}

.order-item:hover {
  box-shadow: rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

.order-item .cancel-button {
  align-self: flex-end;
  margin-bottom: -45px;
  color: #c5c6c7;
  border-color: #c5c6c7;
}

.order-item .cancel-button:hover {
  color: #8e8e93;
  border-color: #8e8e93;
}

.order-item-details {
  display: none;
}

.order-down-arrow {
  font-size: 1.3rem;
  margin-left: auto;
  margin-top: -20px;
  cursor: pointer;
}

.status-icon {
  font-size: 1.3rem;
}

.status-text {
  margin-top: -26px;
  display: block;
  margin-left: 26px;
}

.order-product {
  padding: 4px 0;
}

.order-item-title {
  font-size: 14px;
  font-weight: bold;
  padding: 4px 0;
}

.order-item-status {
  padding: 10px 0;
}

.order-item-address {
  line-height: 22px;
}